import type {
  PaginatedModeration,
  QueryPostModerationItemsArgs,
} from '@tribeplatform/gql-client/types'
import type { PaginatedModerationFields } from '@tribeplatform/gql-client/types/generated'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostModerationItemsKey } from '../../utils/keys/moderation.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'

export const usePostModerationItems = (options: {
  variables: QueryPostModerationItemsArgs
  fields?: PaginatedModerationFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedModeration>
}) => {
  const { data: authMember } = useAuthMember()

  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const moderationItemsKey = getPostModerationItemsKey({ variables, fields })

  return useInfiniteQuery<PaginatedModeration>(
    moderationItemsKey,
    ({ pageParam: after }) =>
      client?.query({
        name: 'postModerationItems',
        args: {
          variables: {
            after,
            ...variables,
          },
          fields,
        },
      }),
    { ...useInfiniteQueryOptions, enabled: !!authMember?.staffReasons?.length },
  )
}

import type { ModerationItemFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedModeration,
  QueryModerationItemsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getModerationItemsKey } from '../../utils/keys/moderation.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'

export const useModerationItems = (options: {
  variables: QueryModerationItemsArgs
  fields?: ModerationItemFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedModeration>
}) => {
  const { data: authMember } = useAuthMember()

  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const moderationItemsKey = getModerationItemsKey({ variables, fields })

  return useInfiniteQuery<PaginatedModeration>(
    moderationItemsKey,
    ({ pageParam: after }) =>
      client?.moderation.getItems({ after, ...variables }, fields),
    { ...useInfiniteQueryOptions, enabled: !!authMember?.staffReasons?.length },
  )
}
